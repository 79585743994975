import React from 'react';
import ReactDOM from 'react-dom';

import Page from 'vivino-js/utilities/Page';

import Contact from '@components/Contact/Contact';

ReactDOM.render(
  <Page latest>
    <Contact />
  </Page>,
  document.getElementById('contact-page')
);
